<template>
  <Component v-bind:is="displayTerminalComponent()" />
</template>

<script>
import {mapGetters} from "vuex";
import AllRouteDashboard from "../../transportCompany/route/AllRouteDashboard";
export default {
  name: "ViewTerminalManagerRoute",
  computed: {
    ...mapGetters("terminalManagerRouteComponent", ["currentComponent"]),
  },
  methods:{
    displayTerminalComponent() {
      let component = this.currentComponent;
      if (component === "allRoutes"){
        return AllRouteDashboard
      }
    }
},
  created() {
    let component = sessionStorage.getItem(
        "terminalManagerRouteComponent"
    );
    if (!component){
      sessionStorage.setItem(
          "terminalManagerRouteComponent",
          "allRoutes"
      );
      this.$store.dispatch(
          "terminalManagerRouteComponent/setCurrentComponent",
          "allRoutes"
      );
      this.displayTerminalComponent();
    }
    else {
      this.$store.dispatch(
          "terminalManagerRouteComponent/setCurrentComponent",
          component
      );
      this.displayTerminalComponent();
    }
  }
}
</script>

<style scoped>

</style>